import React from "react";
import Crypto from "app/pages/dashboard/Crypto/Crypto";
import Page from "@jumbo/shared/Page";
import Login from "app/pages/auth-pages/login/Login";
import UserProfile from "app/pages/users/user-profile/UserProfile";
import UnilevelReport from "app/pages/reports/unilevel/UnilevelReport";
import ReferralBonusReport from "app/pages/reports/refferal/ReferralBonusReport";
import DepositReport from "app/pages/reports/deposit/DepositReport";
import Logout from "app/pages/auth-pages/logout/Logout";
import RoiReport from "app/pages/reports/roi/roi";
import ForgotPassword from "app/pages/auth-pages/forgot-password/ForgotPassword";
import ResetPassword from "app/pages/auth-pages/reset-password/ResetPassword";
import BinaryTree from "app/pages/treeview/BinaryTree";
import Test from "app/pages/Test";
import faq from "app/pages/faq/faq";
import PaymentMethod from "app/pages/paymentmethod/method";
import Active from "app/pages/users/active";
import Inactive from "app/pages/users/inactive"; 
import CreateAdmin from "app/pages/mini-admin/create/createadmin";
import ManageAdmin from "app/pages/mini-admin/manage/manageadmin";
import Error404 from "app/pages/Error404/Error404";
import UpdateSponsor from "app/pages/updatesponsor/updatesponsor";
import ManualPayments from "app/pages/payments/manualPayments";
import ApprovedManualPayments from "app/pages/payments/approvedManualPayments";
import RejectedManualPayments from "app/pages/payments/rejectedManualPayments";
import Updatewithdrawal from "app/pages/settings/updatewithdrawal/Updatewithdrawal";
import Managezoom from "app/pages/settings/managezoom/Managezoom";
import Managecommission from "app/pages/settings/managecommission/Managecommission";
import Manageunilevelbonus from "app/pages/settings/manageunilevelbonus/Manageunilevelbonus";
import Managetransactionpassword from "app/pages/managetransactionpassword/Managetransactionpassword";
import Addnews from "app/pages/news/addnews/Addnews";
import Managenews from "app/pages/news/managenews/Managenews";
import Updateuserbalance from "app/pages/updateuserbalance/Updateuserbalance";
import ListofAlluser from "app/pages/userlist/listofalluser/ListofAlluser";
import Updateuserlist from "app/pages/userlist/updateuserlist/Updateuserlist";
import KYCPending from "app/pages/kycreports/pending/KYCPending";
import Approvedkyc from "app/pages/kycreports/approved/Approvedkyc";
import Rejected from "app/pages/kycreports/rejected/Rejectedkyc";
import Subscriptionreport from "app/pages/reports/subscriptionsreport/Subscriptionreport";
import Affiliatereport from "app/pages/reports/affiliatereport/Affiliatereport";
import Updateloginpassword from "app/pages/userlist/updateloginpassword/Updateloginpassword";
import Updatetransactionpassword from "app/pages/userlist/updatetransactionpassword/Updatetransactionpassword";
import Updateuserpassword from "app/pages/userlist/updateuserpassword/Updateuserpassword";
import Userlisttable from "app/pages/userlist/getuserslist/Userlisttable";
import Rankreport from "app/pages/reports/rankreport/Rankreport";
import AddProduct from "app/pages/products/addproduct/addproduct";
import Manageproduct from "app/pages/products/manageproduct/Manageproduct";
import PendingOrder from "app/pages/orders/pendingOrder/PendingOrder";
import ApprovedOrder from "app/pages/orders/approvedOrders/ApprovedOrder";
import PendingPayout from "app/pages/reports/payout/PendingPayout";
import ApprovedPayout from "app/pages/reports/payout/ApprovedPayout";
import RejectedPayout from "app/pages/reports/payout/RejectedPayout";
import ShippingCharges from "app/pages/settings/manageshipping/ShippingCharges";
import BinaryBonus from "app/pages/reports/binary/BinaryBonus";
import PoolBonus from "app/pages/reports/poolbonus/PoolBonus";
import ManagePackages from "app/pages/settings/packages/ManagePackages";
import Ranks from "app/pages/settings/manageranks/Ranks";
import AddCities from "app/pages/settings/cities/AddCities";
import ManageCities from "app/pages/settings/cities/ManageCities";
import StockistUsers from "app/pages/users/StockistUsers";
import DepositWallet from "app/pages/settings/depositwallet/DepositWallet";
import PendingDeposit from "app/pages/deposit/PendingDeposit";
import ApprovedDeposit from "app/pages/deposit/ApprovedDeposit";
import RejectedDeposit from "app/pages/deposit/RejectedDeposit";
import NewPackageRequests from "app/pages/stockist/packages/NewPackageRequests";
import ApprovedPackages from "app/pages/stockist/packages/ApprovedPackages";
import OrderHistory from "app/pages/stockist/Order/OrderHistory";
import PendingAchievers from "app/pages/rankachievers/PendingAchievers";
import ApprovedAchievers from "app/pages/rankachievers/ApprovedAchievers";
import ExpiredAchievers from "app/pages/rankachievers/ExpiredAchievers";
import StockistBonusReport from "app/pages/reports/stockist/StockistBonusReport";
import PairingBonusReport from "app/pages/reports/pairingbonus/PairingBonusReport";
import RankBonusReport from "app/pages/reports/RankBonus/RankBonusReport";
import CreditReport from "app/pages/reports/creditreport/CreditReport";
import DebitReport from "app/pages/reports/debitreport/DebitReport";
import SendMessage from "app/pages/messages/SendMessage";
import Inbox from "app/pages/messages/Inbox";
import Sent from "app/pages/messages/Sent";
import Managepopup from "app/pages/popup/Managepopup";
import Addvideo from "app/pages/videos/addvideo/Addvideo";
import Managevideos from "app/pages/videos/managevideos/Managevideos";
import Roi from "app/pages/reports/roi/roi";
import UpgradePackages from "app/pages/stockist/packages/UpgradePackages";
import AddPdf from "app/pages/pdf/addpdf/AddPdf";
import ManagePdf from "app/pages/pdf/managepdf/ManagePdf";
import AddNfts from "app/pages/store/nfts/AddNfts";
import ManageNfts from "app/pages/store/nfts/ManageNfts";
import PendingNftsOrder from "app/pages/store/orders/PendingNftsOrder";
import ApprovedNftsOrder from "app/pages/store/orders/ApprovedNftsOrder";
import DeclineNftsOrder from "app/pages/store/orders/DeclineNftsOrder";
/**
 routes which you want to make accessible to both authenticated and anonymous users
 **/
const routesForPublic = [



];

/**
 routes only accessible to authenticated users
 **/

const routesForAuthenticatedOnly = [
    {
        path: "/",
        element: <Page component={Crypto} />
    },
    {
        path: "/dashboard",
        element: <Page component={Crypto} />
    },
    {
        path: "/create-admin",
        element: <Page component={CreateAdmin} />
    },
    {
        path: "/manage-admin",
        element: <Page component={ManageAdmin} />
    },
    {
        path: "/add-product",
        element: <Page component={AddProduct} />
    },
    {
        path: "/manage-products",
        element: <Page component={Manageproduct} />
    },
    {
        path: "/add-pdf",
        element: <Page component={AddPdf} />
    },
    {
        path: "/manage-pdf",
        element: <Page component={ManagePdf} />
    },
    // {
    //     path: "/pendingorder",
    //     element: <Page component={PendingOrder} />
    // },
    // {
    //     path: "/approvedorder",
    //     element: <Page component={ApprovedOrder} />
    // },
    {
        path: "/active-users",
        element: <Page component={Active} />
    },
    {
        path: "/inactive-users",
        element: <Page component={Inactive} />
    },
    {
        path: "/stockist-users",
        element: <Page component={StockistUsers} />
    },
    // {
    //     path: "/change-sponsor",
    //     element: <Page component={UpdateSponsor} />
    // },    
    {
        path: "/profile",
        element: <Page component={UserProfile} />
    },
    {
        path: "/addnews",
        element: <Page component={Addnews} />
    },
    {
        path: "/pending-payout",
        element: <Page component={PendingPayout} />
    },
    {
        path: "/approved-payout",
        element: <Page component={ApprovedPayout} />
    },
    {
        path: "/rejected-payout",
        element: <Page component={RejectedPayout} />
    },
    {
        path: "/pending-deposit",
        element: <Page component={PendingDeposit} />
    },
    {
        path: "/approved-deposit",
        element: <Page component={ApprovedDeposit} />
    },
    {
        path: "/rejected-deposit",
        element: <Page component={RejectedDeposit} />
    },
    {
        path: "/pending-packages",
        element: <Page component={NewPackageRequests} />
    },
    {
        path: "/approved-packages",
        element: <Page component={ApprovedPackages} />
    },
    {
        path: "/upgrade-packages",
        element: <Page component={UpgradePackages} />
    },
    {
        path: "/order-history",
        element: <Page component={OrderHistory} />
    },
    {
        path: "/pending-achievers",
        element: <Page component={PendingAchievers} />
    },
    {
        path: "/approved-achievers",
        element: <Page component={ApprovedAchievers} />
    },
    {
        path: "/expired-achievers",
        element: <Page component={ExpiredAchievers} />
    },
    {
        path: "/binary-tree",
        element: <Page component={BinaryTree} />
    },
    {
        path: "/binary-tree/:randomcode",
        element: <Page component={BinaryTree} />
    },
    // {
    //     path: "/payment-method",
    //     element: <Page component={PaymentMethod} />
    // },
    // {
    //     path: "/deposit-report",
    //     element: <Page component={DepositReport} />
    // },
    {
        path: "/manage-deposit-wallet",
        element: <Page component={DepositWallet} />
    },
    // {
    //     path: "/pending-manual-payments",
    //     element: <Page component={ManualPayments} />
    // },

    // {
    //     path: "/approved-manual-payments",
    //     element: <Page component={ApprovedManualPayments} />
    // },
    // {
    //     path: "/rejected-manual-payments",
    //     element: <Page component={RejectedManualPayments} />
    // },
    {
        path: "/manage-packages",
        element: <Page component={ManagePackages} />
    },
    {
        path: "/manage-ranks",
        element: <Page component={Ranks} />
    },
    {
        path: "/add-city",
        element: <Page component={AddCities} />
    },
    {
        path: "/manage-city",
        element: <Page component={ManageCities} />
    },
    // {
    //     path: "/faq",
    //     element: <Page component={faq} />
    // },

    // {
    //     path: "/test",
    //     element: <Page component={Test}  />
    // },
    {
        path: "/updatewithdrawal",
        element: <Page component={Updatewithdrawal} />
    },
    {
        path: "/kycpending",
        element: <Page component={KYCPending}  />
    },
    {
        path: "/approvedkyc",
        element: <Page component={Approvedkyc}  />
    },
    {
        path: "/rejectedkyc",
        element: <Page component={Rejected}  />
    },
    // {
    //     path: "/subscriptionreport",
    //     element: <Page component={Subscriptionreport}  />
    // },
    // {
    //     path: "/affiliatereport",
    //     element: <Page component={Affiliatereport}  />
    // },
    {
        path: "/managezoom",
        element: <Page component={Managezoom} />
    },
    {
        path: "/managecommission",
        element: <Page component={Managecommission} />
    },
    {
        path: "/updateloginpassword",
        element: <Page component={Updateloginpassword} />
    },
    {
        path: "/updatetransactionpassword",
        element: <Page component={Updatetransactionpassword} />
    },
    {
        path: "/updateuserpassword",
        element: <Page component={Updateuserpassword} />
    },
    {
        path: "/unilevel-bonus",
        element: <Page component={UnilevelReport} />
    },
    {
        path: "/referral-bonus",
        element: <Page component={ReferralBonusReport} />
    },
    {
        path: "/stockist-bonus",
        element: <Page component={StockistBonusReport} />
    },
    {
        path: "/residual-bonus",
        element: <Page component={Roi} />
    },
    {
        path: "/unilevel-bonus",
        element: <Page component={UnilevelReport} />
    },
    {
        path: "/pool-bonus",
        element: <Page component={PoolBonus} />
    },
    {
        path: "/rank-bonus",
        element: <Page component={RankBonusReport} />
    },
    // {
    //     path: "/manage-shipping",
    //     element: <Page component={ShippingCharges}  />
    // },
    // {
    //     path: "/manageunilevelbonus",
    //     element: <Page component={Manageunilevelbonus} />
    // },
    // {
    //     path: "/managetransactionpassword",
    //     element: <Page component={Managetransactionpassword}  />
    // },
    
    {
        path: "/managepopup",
        element: <Page component={Managepopup} />
    },
    {
        path: "/inbox",
        element: <Page component={Inbox} />
    },
    {
        path: "/sentmessages",
        element: <Page component={Sent} />
    },
    {
        path: "/managenews",
        element: <Page component={Managenews} />
    },
    {
        path: "/addvideo",
        element: <Page component={Addvideo} />
    },
    {
        path: "/managevideos",
        element: <Page component={Managevideos} />
    },
    {
        path: "/updateuserbalance",
        element: <Page component={Updateuserbalance}  />
    },
    {
        path: "/credit-history",
        element: <Page component={CreditReport}  />
    },
    {
        path: "/debit-history",
        element: <Page component={DebitReport}  />
    },
    {
        path: "/composemessage",
        element: <Page component={SendMessage}  />
    },
    {
        path: "/debit-history",
        element: <Page component={DebitReport}  />
    },
    {
        path: "/debit-history",
        element: <Page component={DebitReport}  />
    },
    {
        path: "/add-nft",
        element: <Page component={AddNfts}  />
    },
    {
        path: "/manage-nft",
        element: <Page component={ManageNfts}  />
    },
    {
        path: "/pending-order",
        element: <Page component={PendingNftsOrder}  />
    },
    {
        path: "/approved-order",
        element: <Page component={ApprovedNftsOrder}  />
    },
    {
        path: "/decline-order",
        element: <Page component={DeclineNftsOrder}  />
    },
    // {
    //     path: "/listofAlluser",
    //     element: <Page component={ListofAlluser}  />
    // },
    // {
    //     path: "/updateuserlist",
    //     element: <Page component={Updateuserlist}  />
    // },
    {
        path: "/logout",
        element: <Page component={Logout} />
    },
    {
        path: "/404",
        element: <Page component={Error404} />
    },
];

/**
 routes only accessible when user is anonymous
 **/
const routesForNotAuthenticatedOnly = [
    {
        path: "/login",
        element: <Page component={Login} />
    },
    {
        path: "/login/:token/:email",
        element: <Page component={Login} />
    },

    {
        path: "/forget-password",
        element: <Page component={ForgotPassword} />
    },
    {
        path: "/reset-password/:token/:email",
        element: <Page component={ResetPassword} />
    },
];


const routes = [
    ...routesForPublic,
    ...routesForAuthenticatedOnly,
    ...routesForNotAuthenticatedOnly,
];

export { routes as default, routesForPublic, routesForNotAuthenticatedOnly, routesForAuthenticatedOnly };