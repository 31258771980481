import JumboDemoCard from '@jumbo/components/JumboDemoCard'
import Div from '@jumbo/shared/Div'
import { Box, CircularProgress, FormHelperText, Grid, IconButton, MenuItem, Stack, Tooltip, Typography } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import SweetAlert from 'app/pages/components/mui/Alerts/SweetAlert'
import { postRequest } from 'backendServices/ApiCalls'
import React, { useEffect, useState } from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { FileCopy as FileCopyIcon } from '@mui/icons-material';
import Swal from 'sweetalert2'
import { format } from "date-fns";

export default function ApprovedNftsOrder() {
  const [copiedRows, setCopiedRows] = useState([]);
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false);
  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: ""
  })


  useEffect(() => {
    setLoading(true);
    postRequest('/get-accept-nft-orders', {}, (response) => {
      setLoading(false);
      if (response?.data?.status === 'success') {
        // console.log("🚀 ~ postRequest ~ response?.data?.data:", response?.data?.data)
        const data = response?.data?.data?.map((row, index) => ({
          id: row.id,
          index: index + 1,
          username: row.username,
          paymentmethod: row.paymentmethod,
          walletaddress: row.walletaddress,
          request: row.created_at,
          nftlink: row.nftlink,
          file: row.file,
          price: row.price,
          imgtype: row.imgtype,
          transactionid: row.transactionid,
          imageURL: response?.data?.imageURL
        }));
        setData(data);
      }
    },
      (error) => {
        setLoading(false);
        console.log(error?.response?.data);
      }
    );
  }, []);

  const columns = [
    {
      field: "index",
      headerName: "Sr#",
      width: 50,
      groupable: false,
      aggregable: false,
    },
    {
      field: "id",
      headerName: "Order ID",
      width: 100,
      groupable: false,
      aggregable: false,
    },
    {
      field: "username",
      headerName: "User Name",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      groupable: false,
      aggregable: false,
    },
    {
      field: "paymentmethod",
      headerName: "Payment Method",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      groupable: false,
      aggregable: false,
    },
    {
      field: "price",
      headerName: "Price",
      dataGeneratorUniquenessEnabled: true,
      width: 100,
      groupable: false,
      aggregable: false,
    },
    {
      field: "walletaddress",
      headerName: "Wallet Address",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      groupable: false,
      aggregable: false,
      renderCell: (params) => {
        const isCopied = copiedRows.includes(params.row.id);
        
        const handleCopyClick = () => {
          navigator.clipboard.writeText(params.value)
            .then(() => {
              setCopiedRows((prevCopiedRows) => [...prevCopiedRows, params.row.id]);
            })
            .catch((error) => {
              console.error('Copy failed:', error);
            });
        };

        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {isCopied ? (
              <CheckCircleIcon style={{ marginRight: '4px', color: 'green' }} />
            ) : (
              <Tooltip title="Copy Address" placement="top">
                <IconButton
                  onClick={handleCopyClick}
                  size="small"
                  style={{ marginRight: '4px' }}
                >
                  <FileCopyIcon />
                </IconButton>
              </Tooltip>
            )}
            <span>{params.value}</span>
          </div>
        );
      },
    },
    {
      field: "transactionid",
      headerName: "Transaction Id",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      groupable: false,
      aggregable: false,
      renderCell: (params) => {
        const isCopied = copiedRows.includes(params.row.id);
        
        const handleCopyClick = () => {
          navigator.clipboard.writeText(params.value)
            .then(() => {
              setCopiedRows((prevCopiedRows) => [...prevCopiedRows, params.row.id]);
            })
            .catch((error) => {
              console.error('Copy failed:', error);
            });
        };

        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {isCopied ? (
              <CheckCircleIcon style={{ marginRight: '4px', color: 'green' }} />
            ) : (
              <Tooltip title="Transaction Id Copy" placement="top">
                <IconButton
                  onClick={handleCopyClick}
                  size="small"
                  style={{ marginRight: '4px' }}
                >
                  <FileCopyIcon />
                </IconButton>
              </Tooltip>
            )}
            <span>{params.value}</span>
          </div>
        );
      },
    },
    {
      field: "request",
      headerName: "Purchased At",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      groupable: false,
      aggregable: false,
      renderCell: (params) => {
        return format(new Date(params.row.request), "MM/dd/yyyy");
      },
    },
    {
      field: "file",
      headerName: "NFT",
      dataGeneratorUniquenessEnabled: true,
      width: 100,
      groupable: false,
      aggregable: false,
      renderCell: (params) => {
        let data = params?.row;
        return (
          <>
            {
              params?.row?.imgtype === "file" ?
                <img src={data.imageURL + data.file} loading='lazy' width="40" alt={params?.row?.title} />
                :
                // <a href={data.file} target='_blank' rel="noreferrer" style={{ color: 'white' }}>{data.file}</a>
                <img src={data.file} loading='lazy' width="40" alt={params?.row?.title} />
            }
          </>
        );
      },
    },
    {
      field: "nftlink",
      headerName: "NFT Link",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      groupable: false,
      aggregable: false,
      renderCell: (params) => {
        return (
          <>
            <a href={params?.row?.nftlink} target='_blank' rel="noreferrer" style={{ color: 'white' }}>{params?.row?.nftlink}</a>
          </>
        );
      },
    }
  ];

  const initialState = {
    initialState: {
      columns: {
        columnVisibilityModel: {
          id: false,
          username: false,
          paymentmethod: false,
          walletaddress: false,
          request: false,
          file: false,
          nftlink: false,
        },
      },
    },
  };

  const gridDesign = {
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
        color: "#8cda24",
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
  };
  return (
    <JumboDemoCard
      title={"Approved Orders"}
      wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
    >
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}
      <Box sx={{ height: 500, width: 1 }}>
        {
          loading ? (
            <Div
              sx={{
                display: "flex",
                minWidth: 0,
                alignItems: "center",
                alignContent: "center",
                height: "100%",
              }}
            >
              <CircularProgress sx={{ m: "-40px auto 0" }} />
            </Div>
          ) : (
            <DataGrid
              initialState={{
                initialState,
                pagination: { paginationModel: { pageSize: 15 } },
              }}
              rows={data}
              getRowId={(row) => row.id}
              columns={columns}
              slots={{ toolbar: GridToolbar }}
              sx={gridDesign}
              pageSizeOptions={[15, 30, 75, 100]}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
            />)
        }
      </Box>
    </JumboDemoCard>
  )
}
