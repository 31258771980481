import JumboDemoCard from '@jumbo/components/JumboDemoCard'
import Div from '@jumbo/shared/Div'
import { Box, CircularProgress, FormHelperText, Grid, IconButton, MenuItem, Stack, Typography } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import SweetAlert from 'app/pages/components/mui/Alerts/SweetAlert'
import { postRequest } from 'backendServices/ApiCalls'
import React, { useEffect, useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField } from '@mui/material';
import * as yup from "yup";
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField'
import { LoadingButton } from '@mui/lab'
import { Form, Formik } from 'formik'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'

export default function ManageNfts() {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [packageList, setPackageList] = useState([]);
  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: ""
  })

  const getNftStore = () => {
    setLoading(true);
    postRequest('/get-nft-store', {}, (response) => {
      setLoading(false);
      if (response?.data?.status === 'success') {
        // console.log("🚀 ~ postRequest ~ response?.data?.data:", response?.data?.data)
        const data = response?.data?.data?.map((row, index) => ({
          id: row.id,
          index: index + 1,
          title: row.title,
          collectionid: row.collectionid,
          collectionname: row.collectionname,
          price: row.price,
          imgtype: row.imgtype,
          file: row.file,
          nftlink: row.nftlink,
          imageURL: response?.data?.imageURL
        }));
        setData(data);
      }
    },
      (error) => {
        setLoading(false);
        console.log(error?.response?.data);
      }
    );
  }
  useEffect(() => {
    getNftStore()
    postRequest('/getpackagesdata', {}, (response) => {
      if (response?.data?.status === 'success') {
        // console.log("🚀 ~ postRequest ~ response?.data?.data:", response?.data?.data)
        const data = response?.data?.data?.map((row, index) => ({
          id: row.id,
          index: index + 1,
          title: row.title,
        }));
        setPackageList(data);
        // setPackageList(response?.data?.data);
      }
    },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  }, []);

  const handleDelete = (id) => {
    // console.log('Delete ID:', id);

    postRequest('/delete-nft', { id }, (response) => {
      if (response?.data?.status === "error") {
        setalertData({
          show: true,
          message: response?.data?.message,
          variant: "error"
        })
      } else if (response?.data?.status === "success") {
        setData((prevData) => prevData.filter((item) => item.id !== id));
        setalertData({
          show: true,
          message: response?.data?.message,
          variant: "success"
        })
      }
    }, (error) => {
      console.log(error?.response?.data);
    })
  };

  const handleUpdate = (id) => {
    // console.log('Update ID:', id);
    const item = data.find(d => d.id === id);
    // console.log("🚀 ~ handleUpdate ~ item:", item)
    setSelectedItem(item);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedItem(null);
  };

  const columns = [
    {
      field: "index",
      headerName: "Sr#",
      width: 50,
      groupable: false,
      aggregable: false,
    },
    {
      field: "collectionname",
      headerName: "Collection Name",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      groupable: false,
      aggregable: false,
    },
    {
      field: "title",
      headerName: "Title",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      groupable: false,
      aggregable: false,
    },
    {
      field: "price",
      headerName: "Price",
      dataGeneratorUniquenessEnabled: true,
      width: 100,
      groupable: false,
      aggregable: false,
    },
    {
      field: "imgtype",
      headerName: "Image Type",
      dataGeneratorUniquenessEnabled: true,
      width: 100,
      groupable: false,
      aggregable: false,
    },
    {
      field: "file",
      headerName: "NFT",
      dataGeneratorUniquenessEnabled: true,
      width: 100,
      groupable: false,
      aggregable: false,
      renderCell: (params) => {
        let data = params?.row;
        return (
          <>
            {
              params?.row?.imgtype === "file" ?
                <img src={data.imageURL + data.file} loading='lazy' width="40" alt={params?.row?.title} />
                :
                // <a href={data.file} target='_blank' rel="noreferrer" style={{ color: 'white' }}>{data.file}</a>
                <img src={data.file} loading='lazy' width="40" alt={params?.row?.title} />
            }
          </>
        );
      },
    },
    {
      field: "nftlink",
      headerName: "NFT Link",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      groupable: false,
      aggregable: false,
      renderCell: (params) => {
        return (
          <>
            <a href={params?.row?.nftlink} target='_blank' rel="noreferrer" style={{ color: 'white' }}>{params?.row?.nftlink}</a>
          </>
        );
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 150,
      renderCell: (params) => (
        <div>
          <IconButton onClick={() => handleUpdate(params.row.id)}>
            <EditIcon sx={{ color: '#4CAF50' }} />
          </IconButton>
          <IconButton onClick={() => handleDelete(params.row.id)}>
            <DeleteIcon sx={{ color: '#F44336' }} />
          </IconButton>
        </div>
      ),
    },
  ];

  const initialState = {
    initialState: {
      columns: {
        columnVisibilityModel: {
          id: false,
          collectionname: false,
          title: false,
          price: false,
          imgtype: false,
          file: false,
          nftlink: false,
        },
      },
    },
  };

  const gridDesign = {
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
        color: "#8cda24",
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
  };

  return (
    <JumboDemoCard
      title={"Manage NFTs"}
      wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
    >
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}
      <Box sx={{ height: 500, width: 1 }}>
        {
          loading ? (
            <Div
              sx={{
                display: "flex",
                minWidth: 0,
                alignItems: "center",
                alignContent: "center",
                height: "100%",
              }}
            >
              <CircularProgress sx={{ m: "-40px auto 0" }} />
            </Div>
          ) : (
            <DataGrid
              initialState={{
                initialState,
                pagination: { paginationModel: { pageSize: 15 } },
              }}
              rows={data}
              getRowId={(row) => row.id}
              columns={columns}
              slots={{ toolbar: GridToolbar }}
              sx={gridDesign}
              pageSizeOptions={[15, 30, 75, 100]}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
            />)
        }
      </Box>
      <UpdateDialog
        open={openDialog}
        item={selectedItem}
        packageList={packageList}
        onClose={handleDialogClose}
        onSubmit={getNftStore}
      />
    </JumboDemoCard>
  )
}






const validationSchema = yup.object({
  package: yup.string().required('Package is required'),
  title: yup.string().required('Title is required'),
  price: yup.number().required('Price is required'),
  imageType: yup.string().required('Image Type is required'),
  nftImgLink: yup.string().when('imageType', { is: 'link', then: yup.string().required('Image Link is required').url('Invalid URL') }),
  image: yup.mixed().when('imageType', { is: 'file', then: yup.mixed().required('Image File is required') }),
  nftLink: yup.string().url('Invalid NFT Link').required('NFT Link is required'),
});

function UpdateDialog({ open, item, packageList, onClose, onSubmit }) {
  const [base64Image, setBase64Image] = useState(null);
  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: ""
  })

  const handleImageUpload = async (file, setFieldValue) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String = reader.result;
      setBase64Image(base64String);
      setFieldValue('image', base64String);
      // console.log("🚀 ~ handleImageUpload ~ base64String:", base64String)
    };
    reader.onerror = (error) => {
      console.error('Error converting file to base64:', error);
    };
  };

  const handleImageRemove = (setFieldValue) => {
    setFieldValue('image', null);
  };

  const handleSubmit = (data, setSubmitting, resetForm) => {
    // console.log("🚀 ~ handleSubmit ~ data:", data)
    let param = {
      id: item.id,
      package: data.package,
      title: data.title,
      price: data.price,
      nftLink: data.nftLink,
      imageType: data.imageType,
      nftImgLink: data.nftImgLink,
      image: data.image,
    }
    // console.log("🚀 ~ handleSubmit ~ param:", param)
    postRequest('/update-nft', param, (response) => {
      if (response?.data?.status === "error") {
        setalertData({
          show: true,
          message: response?.data?.message,
          variant: "error"
        })
        setSubmitting(false)

      } else if (response?.data?.status === "success") {
        resetForm()
        setBase64Image(null);
        onClose();
        onSubmit();
        setalertData({
          show: true,
          message: response?.data?.message,
          variant: "success"
        })
        setSubmitting(false);
      }
    }, (error) => {
      console.log(error?.response?.data);
    })
  };

  return (
    <Dialog open={open} onClose={onClose} sx={{ '& .MuiDialog-paper': { width: '600px' } }}>
      {
        alertData.show && (<SweetAlert alertData={alertData} setalertData={setalertData} />)
      }
      <DialogTitle>Update NFT</DialogTitle>
      <DialogContent>
        <Formik
          validateOnChange={true}
          initialValues={{
            package: item?.collectionid || '',
            title: item?.title || '',
            price: item?.price || '',
            nftLink: item?.nftlink || '',
            imageType: item?.imgtype || '',
            nftImgLink: item?.file || '',
            image: item?.imageURL + item?.file || '',
          }}
          validationSchema={validationSchema}
          onSubmit={(data, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            handleSubmit(data, setSubmitting, resetForm);
          }}
        >
          {({ isSubmitting, setFieldValue, values }) => (
            <Form style={{ textAlign: 'left' }} noValidate autoComplete='off'>
              <Div sx={{ mt: 1, pl: 2, pr: 2 }}>
                <JumboTextField
                  fullWidth
                  name="package"
                  label="Select Package"
                  select
                  value={values.package}
                  onChange={(e) => setFieldValue("package", e.target.value)}
                  required
                >
                  {packageList.map((pkg) => (
                    <MenuItem key={pkg.id} value={pkg.id}>
                      {pkg.title}
                    </MenuItem>
                  ))}
                </JumboTextField>
              </Div>
              <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                <JumboTextField
                  fullWidth
                  name="title"
                  label="Title"
                  type="text"
                  required
                />
              </Div>
              <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                <JumboTextField
                  fullWidth
                  name="price"
                  label="Price"
                  type="number"
                  required
                />
              </Div>
              <Box sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                <JumboTextField
                  fullWidth
                  name="imageType"
                  label="Image Type"
                  select
                  value={values.imageType}
                  onChange={(e) => setFieldValue("imageType", e.target.value)}
                  required
                >
                  <MenuItem value="link">Link</MenuItem>
                  <MenuItem value="file">File</MenuItem>
                </JumboTextField>
              </Box>

              {values.imageType === 'link' && (
                <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                  <JumboTextField
                    fullWidth
                    name="nftImgLink"
                    label="Image Link"
                    type="text"
                    required
                  />
                </Div>
              )}
              {values.imageType === 'file' && (
                <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                  <Stack direction="column" spacing={1} alignItems="center">
                    {values.image ? (
                      // Show thumbnail and text when image is uploaded
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Box sx={{ position: 'relative' }}>
                          <img
                            src={values.image}
                            alt="Uploaded NFT"
                            style={{
                              width: '100px',
                              height: '100px',
                              objectFit: 'cover',
                              borderRadius: '8px',
                              marginBottom: '8px',
                            }}
                          />
                          <IconButton
                            sx={{
                              position: 'absolute',
                              top: '0',
                              right: '0',
                              backgroundColor: 'rgba(0, 0, 0, 0.5)',
                              color: 'white',
                              '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.7)' },
                            }}
                            onClick={() => handleImageRemove(setFieldValue)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                        {/* <img
                          src={values.image}
                          alt="Uploaded NFT"
                          style={{
                            width: '100px',
                            height: '100px',
                            objectFit: 'cover',
                            borderRadius: '8px',
                            marginBottom: '8px'
                          }}
                        /> */}
                        <Typography variant="body2" color="textSecondary">Image Uploaded</Typography>
                      </Box>
                    ) : (
                      // Show upload button if no image is selected
                      <>
                        <Button
                          variant="outlined"
                          component="label"
                          startIcon={<CloudUploadIcon />}
                          sx={{
                            width: '100%',
                            padding: 2,
                            borderColor: 'primary.main',
                            color: 'primary.main',
                            '&:hover': { backgroundColor: 'primary.light', borderColor: 'primary.dark' }
                          }}
                        >
                          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Upload NFT Image</Typography>
                          <input
                            type="file"
                            name="image"
                            accept="image/*"
                            hidden
                            // onChange={(event) => setFieldValue('image', event.currentTarget.files[0])}
                            onChange={(event) => {
                              const file = event.currentTarget.files[0];
                              handleImageUpload(file, setFieldValue);
                            }}
                            required
                            disabled={values.image !== null}  // Disable when file is selected
                          />
                        </Button>
                        <FormHelperText sx={{ textAlign: 'center', color: 'text.secondary' }}>
                          Only image files are allowed
                        </FormHelperText>
                      </>
                    )}
                  </Stack>
                </Div>
              )}
              <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                <JumboTextField
                  fullWidth
                  name="nftLink"
                  label="NFT Link"
                  type="text"
                  required
                />
              </Div>

              <Div sx={{ mt: 1, pl: 2, pr: 2 }}>
                <LoadingButton
                  fullWidth
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{ mb: 3 }}
                  loading={isSubmitting}
                >
                  Submit
                </LoadingButton>
              </Div>
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">Close</Button>
        {/* <Button onClick={handleSubmit} color="primary">Update</Button> */}
      </DialogActions>
    </Dialog>
  )
}



// function UpdateDialog({ open, item, onClose, onSubmit }) {
//   const [title, setTitle] = useState(item?.title || '');
//   const [collectionName, setCollectionName] = useState(item?.collectionname || '');
//   const [price, setPrice] = useState(item?.price || '');
//   const [nftLink, setNftLink] = useState(item?.nftlink || '');
//   const [imageType, setImageType] = useState(item?.imgtype || 'file');
//   const [file, setFile] = useState(item?.file || '');

//   const handleFileChange = (e) => {
//     setFile(e.target.files[0]);
//   };

//   const handleSubmit = () => {
//     const updatedItem = {
//       ...item,
//       title,
//       collectionname: collectionName,
//       price,
//       nftlink: nftLink,
//       imgtype: imageType,
//       file: file instanceof File ? file.name : file,
//     };

//     onSubmit(updatedItem);
//     onClose();
//   };

//   return (
//     <Dialog open={open} onClose={onClose}>
//       <DialogTitle>Update NFT</DialogTitle>
//       <DialogContent>
//         <TextField label="Title" fullWidth margin="dense" value={title} onChange={(e) => setTitle(e.target.value)} />
//         <TextField label="Collection Name" fullWidth margin="dense" value={collectionName} onChange={(e) => setCollectionName(e.target.value)} />
//         <TextField label="Price" fullWidth margin="dense" type="number" value={price} onChange={(e) => setPrice(e.target.value)} />
//         <TextField label="NFT Link" fullWidth margin="dense" value={nftLink} onChange={(e) => setNftLink(e.target.value)} />
//         <TextField label="Image Type" fullWidth margin="dense" value={imageType} onChange={(e) => setImageType(e.target.value)} />
//         {imageType === 'file' && <input type="file" onChange={handleFileChange} />}
//       </DialogContent>
//       <DialogActions>
//         <Button onClick={onClose} color="secondary">Cancel</Button>
//         <Button onClick={handleSubmit} color="primary">Update</Button>
//       </DialogActions>
//     </Dialog>
//   );
// }