import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import Div from '@jumbo/shared/Div';
import { LoadingButton } from '@mui/lab';
import { Autocomplete, Box, Button, FormHelperText, Grid, Input, MenuItem, Stack, TextField, Typography } from '@mui/material';
import SweetAlert from 'app/pages/components/mui/Alerts/SweetAlert';
import { postRequest } from 'backendServices/ApiCalls';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import * as yup from "yup";
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';

const validationSchema = yup.object({
    package: yup.string().required('Package is required'),
    title: yup.string().required('Title is required'),
    price: yup.number().required('Price is required'),
    imageType: yup.string().required('Image Type is required'),
    nftImgLink: yup.string().when('imageType', { is: 'link', then: yup.string().required('Image Link is required').url('Invalid URL') }),
    image: yup.mixed().when('imageType', { is: 'file', then: yup.mixed().required('Image File is required') }),
    nftLink: yup.string().url('Invalid NFT Link').required('NFT Link is required'),
});

export default function AddNfts() {
    const [base64Image, setBase64Image] = useState(null);
    const [packageList, setPackageList] = useState([]);
    const [alertData, setalertData] = useState({
        show: false,
        message: "",
        variant: ""
    })

    useEffect(() => {
        postRequest('/getpackagesdata', {}, (response) => {
            if (response?.data?.status === 'success') {
                // console.log("🚀 ~ postRequest ~ response?.data?.data:", response?.data?.data)
                const data = response?.data?.data?.map((row, index) => ({
                    id: row.id,
                    index: index + 1,
                    title: row.title,
                  }));
                setPackageList(data);
            }
        },
            (error) => {
                console.log(error?.response?.data);
            }
        );
    }, []);

    const handleImageUpload = async (file, setFieldValue) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const base64String = reader.result;
            setBase64Image(base64String);
            setFieldValue('image', base64String);
            // console.log("🚀 ~ handleImageUpload ~ base64String:", base64String)
        };
        reader.onerror = (error) => {
            console.error('Error converting file to base64:', error);
        };
    };

    const handleSubmit = (data, setSubmitting, resetForm) => {
        // console.log("🚀 ~ handleSubmit ~ data:", data)
        postRequest('/add-nft', data, (response) => {
            if (response?.data?.status === "error") {
                setalertData({
                    show: true,
                    message: response?.data?.message,
                    variant: "error"
                })
                setSubmitting(false)

            } else if (response?.data?.status === "success") {
                resetForm()
                setBase64Image(null);
                setalertData({
                    show: true,
                    message: response?.data?.message,
                    variant: "success"
                })
                setSubmitting(false);
            }
        }, (error) => {
            console.log(error?.response?.data);
        })
    };
    return (
        <Grid container fullWidth sm={12} xs={12} p={2} alignItems="center" justifyContent="center">
            <Grid item sm={6} xs={12}>
                <JumboCardQuick title={"Add NFTs"} noWrapper >
                    {
                        alertData.show && (<SweetAlert alertData={alertData} setalertData={setalertData} />)
                    }
                    <Formik
                        validateOnChange={true}
                        initialValues={{
                            package: '',
                            title: '',
                            price: '',
                            nftLink: '',
                            imageType: '',
                            nftImgLink: '',
                            image: null,
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(data, { setSubmitting, resetForm }) => {
                            setSubmitting(true);
                            handleSubmit(data, setSubmitting, resetForm);
                        }}
                    >
                        {({ isSubmitting, setFieldValue, values }) => (
                            <Form style={{ textAlign: 'left' }} noValidate autoComplete='off'>
                                <Div sx={{ mt: 1, pl: 2, pr: 2 }}>
                                    <JumboTextField
                                        fullWidth
                                        name="package"
                                        label="Select Package"
                                        select
                                        value={values.package}
                                        onChange={(e) => setFieldValue("package", e.target.value)}
                                        required
                                    >
                                        {packageList.map((pkg) => (
                                            <MenuItem key={pkg.id} value={pkg.id}>
                                                {pkg.title}
                                            </MenuItem>
                                        ))}
                                    </JumboTextField>
                                </Div>
                                <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                                    <JumboTextField
                                        fullWidth
                                        name="title"
                                        label="Title"
                                        type="text"
                                        required
                                    />
                                </Div>
                                <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                                    <JumboTextField
                                        fullWidth
                                        name="price"
                                        label="Price"
                                        type="number"
                                        required
                                    />
                                </Div>
                                <Box sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                                    <JumboTextField
                                        fullWidth
                                        name="imageType"
                                        label="Image Type"
                                        select
                                        value={values.imageType}
                                        onChange={(e) => setFieldValue("imageType", e.target.value)}
                                        required
                                    >
                                        <MenuItem value="link">Link</MenuItem>
                                        <MenuItem value="file">File</MenuItem>
                                    </JumboTextField>
                                </Box>

                                {values.imageType === 'link' && (
                                    <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                                        <JumboTextField
                                            fullWidth
                                            name="nftImgLink"
                                            label="Image Link"
                                            type="text"
                                            required
                                        />
                                    </Div>
                                )}

                                {/* {values.imageType === 'file' && (
                                    <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                                    <Stack direction="column" spacing={1} alignItems="center">
                                        <Button
                                            variant="outlined"
                                            component="label"
                                            startIcon={<CloudUploadIcon />}
                                            sx={{
                                                width: '100%',
                                                padding: 2,
                                                borderColor: 'primary.main',
                                                color: 'primary.main',
                                                '&:hover': { backgroundColor: 'primary.light', borderColor: 'primary.dark' }
                                            }}
                                        >
                                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Upload NFT Image</Typography>
                                            <input
                                                type="file"
                                                name="nftFile"
                                                accept="image/*"
                                                hidden
                                                onChange={(event) => setFieldValue('nftFile', event.currentTarget.files[0])}
                                                required
                                                disabled={values.nftFile !== null}
                                            />
                                        </Button>
                                        <FormHelperText sx={{ textAlign: 'center', color: 'text.secondary' }}>
                                            Only image files are allowed
                                        </FormHelperText>
                                    </Stack>
                                </Div>
                                )} */}

                                {values.imageType === 'file' && (
                                    <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                                        <Stack direction="column" spacing={1} alignItems="center">
                                            {values.image ? (
                                                // Show thumbnail and text when image is uploaded
                                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                    <img
                                                        src={base64Image}
                                                        alt="Uploaded NFT"
                                                        style={{
                                                            width: '100px',
                                                            height: '100px',
                                                            objectFit: 'cover',
                                                            borderRadius: '8px',
                                                            marginBottom: '8px'
                                                        }}
                                                    />
                                                    <Typography variant="body2" color="textSecondary">Image Uploaded</Typography>
                                                </Box>
                                            ) : (
                                                // Show upload button if no image is selected
                                                <>
                                                    <Button
                                                        variant="outlined"
                                                        component="label"
                                                        startIcon={<CloudUploadIcon />}
                                                        sx={{
                                                            width: '100%',
                                                            padding: 2,
                                                            borderColor: 'primary.main',
                                                            color: 'White',
                                                            '&:hover': { backgroundColor: 'primary.light', borderColor: 'primary.dark' }
                                                        }}
                                                    >
                                                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Upload NFT Image</Typography>
                                                        <input
                                                            type="file"
                                                            name="image"
                                                            accept="image/*"
                                                            hidden
                                                            // onChange={(event) => setFieldValue('image', event.currentTarget.files[0])}
                                                            onChange={(event) => {
                                                                const file = event.currentTarget.files[0];
                                                                handleImageUpload(file, setFieldValue);
                                                            }}
                                                            required
                                                            disabled={values.image !== null}  // Disable when file is selected
                                                        />
                                                    </Button>
                                                    <FormHelperText sx={{ textAlign: 'center', color: 'text.secondary' }}>
                                                        Only image files are allowed
                                                    </FormHelperText>
                                                </>
                                            )}
                                        </Stack>
                                    </Div>
                                )}

                                {/* <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                      <JumboTextField
                        fullWidth
                        name="notes"
                        label="Notes"
                        type="text"
                        multiline
                        rows={4} // You can adjust the number of rows as needed   
                        required               
                      />
                    </Div> */}

                                <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                                    <JumboTextField
                                        fullWidth
                                        name="nftLink"
                                        label="NFT Link"
                                        type="text"
                                        required
                                    />
                                </Div>

                                <Div sx={{ mt: 1, pl: 2, pr: 2 }}>
                                    <LoadingButton
                                        fullWidth
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        sx={{ mb: 3 }}
                                        loading={isSubmitting}
                                    >
                                        Submit
                                    </LoadingButton>
                                </Div>
                            </Form>
                        )}
                    </Formik>
                </JumboCardQuick>
            </Grid>
        </Grid>
    )
}
